import React, { Component } from "react";
import styles from "./Loading.module.css";

export default class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {}

    render() {
        return (
            <div className={`${this.props.className}`}>
                <div className={`${styles.container}`}>
                    <img draggable={false} alt={`Loading`} src={"/common/logo-foam.png"} />

                    <p>loading...</p>
                </div>
            </div>
        );
    }
}
