import React, { Component } from 'react'
import {
    Link,
    withRouter
  } from 'react-router-dom'
import './Blog.css'
import Helmet from 'react-helmet'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageHeader from '../../components/PageHeader'

class BlogPage extends Component {

  async componentDidMount() {
    if(!document.getElementById("blog").classList.contains("activeMenu")){
      document.getElementById("blog").classList.add("activeMenu");
    }
    window.scroll(0, 0);
  }

  render() {

    const blogArticles = [
      [
        {"title":"The monsters in my Carpet"},
        {"img":"/blog/viruses1.jpg"},
        {"excerpt":"When was the last time you dropped a Jaffa cake or snickers on the carpet or mattress and then put it straight in your mouth? What do you imagine was attached to that? Have you ever wondered if you were to take a sample of what is in your mattress or carpet, what you would find on a microscopic level?"},
        {"link":"monsters-in-my-carpet"},
      ],
      [
        {"title":"Science behind the need for deep cleaning your carpets"},
        {"img":"/blog/science.jpg"},
        {"excerpt":"Let’s apply the 5 second rule to test your knowledge of how clean you think household surfaces are. If your Favourite biscuit dropped on these surfaces, after having rescued it, which one would you eat? Would it be the one from?"},
        {"link":"science-behind-deep-cleaning-carpets"},
      ],
      [
        {"title":"How often should I get my carpets deep cleaned?"},
        {"img":"/blog/how-often.jpg"},
        {"excerpt":"Most people consider carpet deep cleaning when they notice that the carpet appears filthy and regular vacuum cleaning is not doing the trick. It’s most definitely the time for a deep carpet cleaning. In reality however that carpet was probably due a deep clean for months or years. Everyday traffic especially from pets and kids will deposit all sorts of filth in the carpets and will require regular professional hot water extraction carpet cleaning."},
        {"link":"how-often-should-i-get-my-carpets-cleaned"},
      ],
      [
        {"title":"Carpet Cleaning: Hot Water Extraction OR Steam Cleaning"},
        {"img":"/blog/steam-vs-hotwater.jpg"},
        {"excerpt":"If you are considering having your carpets cleaned the one question that will confuse you is the difference between steam cleaning and hot water extraction cleaning. The terms have become so misused in the industry that no one really uses them to describe the actual specific process but both terms are usually used interchangeably to refer to deep carpet cleaning in general. In actual fact the two are completely different ways of cleaning carpet. Both processes utilise different temperatures of water. Don’t try to clean your carpet or try to treat a nasty stain, learn more about hot water extraction &steam cleaning."},
        {"link":"hot-water-extraction-or-steam-cleaning"},
      ],
      [
        {"title":"How to… DIY tips for beginners"},
        {"img":"/blog/diy-tips.jpg"},
        {"excerpt":"Most people who are experienced in this job are great for asking about the projects. Initially I was very nervous, while asking question in hardware stores. Those days, I used to think that it may be stupid thing for some people. Today, it’s all right for me. “I am quite new in this project. How can I get this job done?” Though I know little bit about it but it is better to show that you do not know anything."},
        {"link":"how-to-diy-tips-for-beginners"},
      ],
    
    ]

    const listBlogArticles = blogArticles.map((item)=>
      <div className="blogArticleShortWrap" key={item[0].title}>
        <Link to={item[3].link}>
          <h2 className="sectionHead">{item[0].title}</h2>
          <div className="blogPageImg" style={{backgroundImage:`url(${item[1].img})`}}></div>
        </Link>
        <p className="blogExcerpt">{item[2].excerpt}</p>
        <Link to={item[3].link}>
          <p className="readMore">Continue Reading</p>
        </Link>
      </div>
    )

    return (
      <div className="blogPageWrapper">
      <Helmet>
        <title>SW Carpet Cleaning - Blog</title>
        <meta name="description" content="SW Cartpet Cleaning - Blog" />
      </Helmet>

      <PageHeader imageId="quoteHeaderImg" name="Blog"/>
      <Container>
        <Row>
          <Col lg={9}>
            {listBlogArticles}
          </Col>
        </Row>        
      </Container>
      </div>
    )
  }
}

export default withRouter (BlogPage)