import React, { Component } from 'react'
import {
    // Link,
    withRouter
  } from 'react-router-dom'
import './Services.css'
import Container from 'react-bootstrap/Container'
import Helmet from 'react-helmet'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import PageHeader from '../../components/PageHeader'
import CarpetCleaningService from '../../components/CarpetCleaningService'
import RugCleaningService from '../../components/RugCleaningService'
import MattressCleaningService from '../../components/MattressCleaningService'
import CallToAction from '../../components/CallToAction'

class ServicesPage extends Component {

  render() {
    return (
      <div className="servicesPageWrapper">

      <Helmet>
        <title>SW Carpet Cleaning - Services</title>
        <meta name="description" content="SW Cartpet Cleaning" />
      </Helmet>

      <PageHeader imageId="quoteHeaderImg" name="Services"/>

      <Container>
        <CarpetCleaningService />
      </Container>

      <div className="pageDiff">
      <Container>
        <RugCleaningService />
      </Container>
      </div>

      <Container>
        <MattressCleaningService />
      </Container>

      <Container>
        <CallToAction />
      </Container>
      
      </div>
    )
  }
}

export default withRouter (ServicesPage)