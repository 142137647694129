import React, { Component } from 'react'
import {
    // Link,
    withRouter
  } from 'react-router-dom'
import '../pages/Services/Services.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

class RugCleaningService extends Component {

  render() {

    const serviceItems = [
      "Experienced carpet technicians",
      "State of the art commercial carpet cleaning machines",
      "Effectively cleaned carpets",
      "Specialists Stain removal",
      "Quick drying times"
    ];

    const pricingItems = [
      [{"service":"Small Rug"},{"price":"£29.00"}],
      [{"service":"Large Rug"},{"price":"£49.00"}],
    ]

    const listServiceItems = serviceItems.map((item)=>
      <li className="services" key={item}>
        <FontAwesomeIcon icon={faCaretRight} className="servicesIcon"/>
        {item}
      </li>
    )

    const listPricingItems = pricingItems.map((item)=>
      <Row key={item[0].service}>
        <Col lg={4} className="pricing">{item[0].service}</Col>
        <Col lg={2} className="pricing tr">{item[1].price}</Col>
      </Row>
    )

    return (
      <div className="servicePageWrapper">
        <Row>
          <Col lg={9}>
            <h2 className="sectionHead">Property People Rug cleaning service is identical to our deep carpet cleaning</h2>
            {listServiceItems}
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="sectionHead">Pricing</h2>
            {listPricingItems}
          </Col>
        </Row>
        <Row className="singleServiceParagraphs">
          <Col>
            <p className="servicesExplainer light">We use Hot water extraction method for deep carpet cleaning.<br/>
            This is sometimes also referred to as steam cleaning which is a misused term. <br/>
            Read our blog here to learn the difference.</p>
            <p className="servicesExplainer bold">For best results it is recommended that your carpet is deep cleaned every 3 months or sooner. Please see the evidence here.<br/>
            We can offer a discounted annual rate for quarterly deep cleaning of your carpet.</p>
          </Col>
        </Row>
      
      </div>
    )
  }
}

export default withRouter (RugCleaningService)