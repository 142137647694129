import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { publicRequest } from "../requestMethods";
import "./AddJobAdFrom.css";

// Rich Text Editor
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs"; // For edit page

const JobAdForm = styled.form`
    width: 80vw;
    & label {
        color: var(--primary-blue);
        margin-top: 1rem;
    }
    /* height: 70vh; */
`;

const editorOptions = {
    options: ["inline", "list", "link", "blockType"],
    inline: {
        options: ["bold", "italic", "underline", "strikethrough"],
        className: "editor-toolbar",
    },
    list: { options: ["unordered", "ordered"] },
    link: {
        inDropdown: false,
        options: ["link", "unlink"],
        className: "editor-toolbar",
    },
    blockType: {
        inDropdown: false,
        options: ["Normal", "H2", "H3", "H4", "H5", "H6"],
        className: "editor-toolbar font-style",
    },
};

export default function AddJobAdForm({ closeModalAndReload, closeModal, jobAdToEdit }) {
    // Form input states
    const [title, setTitle] = useState("");
    const [pay, setPay] = useState("");
    const [jobType, setJobType] = useState("");

    // Rich text editor state
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    useEffect(() => {
        if (jobAdToEdit) {
            setTitle(jobAdToEdit.title);
            setPay(jobAdToEdit.pay);
            setJobType(jobAdToEdit.type);

            // Convert html prefill draft-js content
            const blocksFromHtml = htmlToDraft(jobAdToEdit.body);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
    }, []);

    // Rich text editor handling
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(title, pay, jobType, editorState);
        const JobAd = {
            title,
            pay,
            type: jobType,
            body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        };

        if (jobAdToEdit) {
            try {
                await publicRequest.put(`/jobads/${jobAdToEdit._id}`, JobAd);
                closeModalAndReload();
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                await publicRequest.post("/jobads", JobAd);
                closeModalAndReload();
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <JobAdForm onSubmit={handleSubmit} className="addJobForm">
            <label>Advert title</label>
            <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
            />
            <div className="row">
                <div className="col-6">
                    <label>Salary / Pay</label>
                    <input
                        type="text"
                        value={pay}
                        onChange={(e) => setPay(e.target.value)}
                        placeholder="Pay"
                    />
                </div>
                <div className="col-6">
                    <label>Job Type (e.g. Full-time)</label>
                    <input
                        type="text"
                        value={jobType}
                        onChange={(e) => setJobType(e.target.value)}
                        placeholder="Job type (e.g. full-time)"
                    />
                </div>
            </div>

            <label>Advert content</label>
            <Editor
                editorState={editorState}
                wrapperClassName="rich-text-wrapper"
                editorClassName="rich-text-editor"
                onEditorStateChange={onEditorStateChange}
                toolbar={editorOptions}
            />
            <button type="submit" class="btn btn-primary mr-3">
                Publish
            </button>
            <button type="button" class="btn btn-secondary" onClick={closeModal}>
                Discard
            </button>
        </JobAdForm>
    );
}
