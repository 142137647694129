import React, { Component } from "react";
import {
  // Link,
  withRouter,
} from "react-router-dom";
import axios from "axios";
import "./Quote.css";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageHeader from "../../components/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import ModalPopup from "../../components/ModalPopup";
import QuoteSwitch from "../../components/QuoteSwitch";

class QuoteCommercialPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Quote values
      propertySize: "0",
      pretreatment: "0",

      // Info boxes
      info: "",
      infoLeft: "",
      infoTop: "",
      infoOpacity: "0",

      // Email related states
      name: "Not provided",
      email: "",
      phone: "Not provided",
      address: "Not provided",
      discount: 1,
      quoteTotal: 0,
      thanksOpacity: 0,
      emailBtnOpacity: 1,
      emailBtnPointerEvents: "all",
      emailValidation: "",
      contactChecked: true,

      // Modal/discount states
      modalOpacity: 0,
      modalPointerEvents: "none",
      noDiscountQuoteOpacity: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onCheckboxSelect = this.onCheckboxSelect.bind(this);
  }

  onNameChange(e) {
    this.setState({ name: e.target.value });
  }

  onEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  onPhoneChange(e) {
    if (isNaN(e.target.value)) {
      console.log("Please use numbers only"); // Handle phone number validation
    } else {
      this.setState({ phone: e.target.value });
    }
  }

  onAddressChange(e) {
    this.setState({ address: e.target.value });
  }

  onCheckboxSelect() {
    this.state.contactChecked
      ? this.setState({ contactChecked: false })
      : this.setState({ contactChecked: true });
    console.log(this.state.contactChecked);
  }

  quoteCalc() {}

  handleSubmit(e) {
    e.preventDefault();

    if (!this.state.email.includes("@" && ".")) {
      // Handle email validation
      this.setState({ emailValidation: "Please enter a valid email address" });
    } else {
      this.setState({
        emailBtnOpacity: 0,
        thanksOpacity: 1,
        emailBtnPointerEvents: "none",
        emailValidation: "",
      });

      let quoteTotal = e.target.name;

      let txt = {
        propertysize: "Carpeted area: " + document.getElementById("propertySize").value,
        pretreatment:
          "Pre-treatment - " + document.getElementById("pretreatment").selectedOptions[0].text,
      };

      let emailToMe = {
        fromEmail: "info@swcarpetcleaning.co.uk",
        toEmail: "info@swcarpetcleaning.co.uk",
        subject: `New quote request from ${this.state.name}`,
        body: `<div style="font-size:18px; line-height:1.5;">
              Name: <b>${this.state.name} </b><br/>
              Email: <b>${this.state.email} </b><br/>
              Phone: <b>${this.state.phone} </b><br/>
              Address: <b>${this.state.address} </b><br/>
              Quote amount: <b>£${quoteTotal} </b> (${txt.propertysize}m<sup>2</sup>)<br/>
              Contact now to book an appointment? - <b>${
                this.state.contactChecked ? "Yes" : "No"
              } </b><br/>
              </div>
              <div style="font-size:14px; padding-top:12px">
                <i>This form was sent using swcarpetcleaning.co.uk quote form.</i>
              </div>`,
      };

      let emailToUser = {
        fromEmail: "info@swcarpetcleaning.co.uk",
        toEmail: `${this.state.email}`,
        subject: `Thank you for interest in our services`,
        body: `<div style="font-size:16px; line-height:1.5;">

      <p>Hello ${this.state.name},</p>
      <p>Thank you for interest in our services. Here is your quote: <b>£${quoteTotal}</b>
      (${txt.propertysize}m<sup>2</sup>)</p>
      <p>This quote is tailored in such a way that allows you to get value for money by doing some of the work yourself like moving furniture or vacuuming beforehand. Or you can leave it all to us. Either way, you will be surprised by the results.</p>
      <p> What happens next?</p>
      <ul>
        <li>If you have checked “Contact me now to book an appointment” we will call you shortly to do exactly that.
        <li>If you were simply curious about our prices, that is fine too. We may follow up with an email to check if you were still interested and if there is anything, we can do to win your business.</li>
      </ul>
      <p>Here at Property People we hire the most skilful builders and tradesman. Sometimes we will hire subcontractors but they will all be backed up by our own comprehensive liability insurance, staff in our office and the best tools & machinery for the jobs we do.</p>
      <p>Our staff are highly trained professionals with health and safety and machines and tools training.</p>
      <p>We look forward to seeing you soon and leaving you with a lovely finish for your refurb project.</p>
      <br>
      <p>Kind Regards</p>
      <p>Property People Care Team.</p>
      <p>P.S. Ask us about our other services. We also do extensions, flooring, decking, kitchens and bathroom.</p>

      </div>
      <div style="font-size:14px; padding-top:12px">
        <i>This form was sent using swcarpetcleaning.co.uk quote form.</i>
      </div>`,
      };

      axios
        .post(
          `https://4088ea0788.execute-api.us-east-1.amazonaws.com/default/contactFormSend`,
          emailToMe
        )
        .then((res) => {
          // console.log(res);
          // console.log(res.data)
          // console.log(emailToMe)
          console.log("Email to me set");
        });

      axios
        .post(
          `https://4088ea0788.execute-api.us-east-1.amazonaws.com/default/contactFormSend`,
          emailToUser
        )
        .then((res) => {
          // console.log(res);
          // console.log(res.data)
          // console.log(emailToUser)
          console.log("Email to user sent");
        });
    }
  }

  handleModal() {
    this.setState({
      discount: 0.9,
      modalOpacity: 0,
      modalPointerEvents: "none",
      noDiscountQuoteOpacity: 1,
    });
  }

  closeModal() {
    this.setState({
      modalOpacity: 0,
      modalPointerEvents: "none",
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleHover(e) {
    this.setState({
      infoTop: e.target.offsetTop + 280,
      infoLeft: e.target.getBoundingClientRect().x + 25,
      infoOpacity: 1,
    });

    switch (e.target.getAttribute("name")) {
      case "propertySize":
        this.setState({
          info: "The cost will be simply £3 per square metre. If it’s a substantially large area and you would like us to come and measure and give you an exact estimate, we are happy to do so.",
        });
        break;
      case "pretreatment":
        this.setState({
          info: "We will spray all of the carpet with a pre-treatment chemical & use a brush or rake to agitate it. This will loosen any other particles that are clinging to it such as dirt, sweet drinks, milk, food and oil, prior to being cleaned. This makes it easier for the carpet to be rid of excess dirt and germs that are compounded down over the years and might have otherwise been missed. Carpet can take a lot of wear and tear and getting it truly clean can be a time-intensive process that takes numerous steps. The additional cost would be £1 per square metre.",
        });
        break;
      default:
        console.log("defs");
    }
  }

  handleMouseOut() {
    this.setState({
      infoOpacity: 0,
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    setTimeout(() => {
      this.setState({
        modalOpacity: 1,
        modalPointerEvents: "all",
      });
    }, 120000);

    document.getElementById("quote").classList.add("activeMenu");
  }

  render() {
    let noDiscountQuote =
      parseFloat(this.state.propertySize) * (3 + parseFloat(this.state.pretreatment));
    let quote = noDiscountQuote * this.state.discount;

    return (
      <div className="quotePageWrapper">
        <Helmet>
          <title>Property People - Commercial Carpet Clean Quote</title>
          <meta name="description" content="SW Cartpet Cleaning - Commercial Carpet Clean Quote" />
        </Helmet>

        <PageHeader
          imageId="quoteCommercialImg"
          name="Commercial Carpet Clean Quote"
          color="white"
        />

        <Container>
          <Row>
            <Col>
              <QuoteSwitch />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Tailor your commercial carpet clean quote</h2>

              <label name="propertySize">
                Approximate carpeted area in square metres.
                <div
                  className="questionMarkW"
                  name="propertySize"
                  onMouseEnter={this.handleHover}
                  onMouseLeave={this.handleMouseOut}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} className="questionMark" />
                </div>
              </label>
              <input
                type="number"
                name="propertySize"
                id="propertySize"
                placeholder="0"
                onChange={this.handleChange}
                style={{ width: "15rem" }}
              />
              <span className="inputbox-after">m2</span>

              {/* <label name="pretreatment">Pre-treatment (recommended for heavily soiled carpets) 
                    <div className="questionMarkW" name="pretreatment" onMouseEnter={this.handleHover} onMouseLeave={this.handleMouseOut}>
                      <FontAwesomeIcon 
                        icon={faQuestionCircle} 
                        className="questionMark"
                      />
                    </div>
                  </label>
                  <select name="pretreatment" id="pretreatment" onChange={this.handleChange}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select> */}

              <h2 className="sectionHeadAlt">Your quote is:</h2>
              <div className="quoteTotal">
                £{quote.toFixed(2)}
                <span className="oldQuote" style={{ opacity: this.state.noDiscountQuoteOpacity }}>
                  £{noDiscountQuote.toFixed(2)}
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Your Details</h2>
            </Col>
          </Row>

          {/* Submit form */}
          <form
            id="quoteForm"
            className="quoteForm"
            onSubmit={this.handleSubmit}
            name={quote.toFixed(2)} // Passing the final quote to Email via name attribute :p
            noValidate
          >
            <Row>
              <Col sm={12} md={4}>
                <label htmlFor="Name">Name*</label>
                <input type="text" onChange={this.onNameChange} required></input>
              </Col>
              <Col sm={12} md={4}>
                <label htmlFor="Email">Email*</label>
                <input type="text" onChange={this.onEmailChange} required></input>
                <div id="emailValidation" className="validationBox">
                  {this.state.emailValidation}
                </div>
              </Col>
              <Col sm={12} md={4}>
                <label htmlFor="Phone">Phone Number</label>
                <input type="text" onChange={this.onPhoneChange}></input>
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="Address">Address</label>
                <input type="text" onChange={this.onAddressChange}></input>
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  type="checkbox"
                  onChange={this.onCheckboxSelect}
                  checked={this.state.contactChecked}
                ></input>
                <label htmlFor="ContactMe" className="contactMe">
                  Contact me now to book an appointment
                </label>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    opacity: this.state.emailBtnOpacity,
                    pointerEvents: this.state.emailBtnPointerEvents,
                  }}
                >
                  Email Me The Quote
                </button>
                <div className="submitThanks" style={{ opacity: this.state.thanksOpacity }}>
                  <strong>Thank you for your enquiry! Email is now on its way.</strong>
                  <br />
                  If you have requested an appointment, we will get back to you as soon as possible.
                </div>
              </Col>
            </Row>
          </form>
        </Container>

        <div
          className="infoBoxQuote"
          style={{
            top: this.state.infoTop + "px",
            left: this.state.infoLeft + "px",
            opacity: this.state.infoOpacity,
          }}
        >
          {this.state.info}
        </div>

        <ModalPopup
          onClick={this.handleModal}
          closeModal={this.closeModal}
          opacity={this.state.modalOpacity}
          pointerEvents={this.state.modalPointerEvents}
        />
      </div>
    );
  }
}

export default withRouter(QuoteCommercialPage);
