import React, { Component } from "react";
import axios from "axios";
import Moment from "react-moment";

import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ReactComponent as GoogleLogoSVG } from "../images/googlereviews/google-logo.svg";
import { ReactComponent as GoogleFiveStars } from "../images/googlereviews/google-5-stars.svg";
import { ReactComponent as GoogleFourStars } from "../images/googlereviews/google-4-stars.svg";

const ReviewWrap = styled.div`
    padding: 1rem;
`;
const Review = styled.div`
    font-size: 0.825rem;
    color: var(--primary-blue);
`;

const Author = styled.div`
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
    padding-top: 0.5rem;
`;

const SummaryColumn = styled.div`
    text-align: center;
`;

const ReviewSummary = styled.div`
    font-size: 1.5rem;
    color: var(--primary-blue);
    font-weight: 500;
`;

const FiveStars = styled(GoogleFiveStars)`
    width: 8rem;
    padding-right: 2rem;
`;

const FourStars = styled(GoogleFourStars)`
    width: 6.7rem;
    padding-right: 2rem;
`;

const ReviewCount = styled.div`
    font-size: 0.825rem;
    color: var(--primary-blue);
    padding-bottom: 0.5rem;
`;

const GoogleLogo = styled(GoogleLogoSVG)`
    width: 40%;
    height: auto;
`;

const StarsDateWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
    padding-bottom: 0.5rem;
`;

const Date = styled.div`
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
    padding-top: 0.5rem;
    text-align: right;
`;

class GoogleWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyData: {},
            isLoaded: false,
        };
    }

    componentDidMount() {
        axios
            .get(
                "https://property-people-reviews.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJk7vXAg8GdkgRh0E6lLUkWFU&key=AIzaSyApGhg2iSG11Ra01IokZvM6-7UBPFofZ3I"
            )
            .then((res) => {
                this.setState({ companyData: res.data.result });
            })
            .then(() => {
                this.setState({ isLoaded: true });
            });
    }

    render() {
        const reviewSliderSettings = {
            infinite: true,
            speed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        return (
            <div className="googleWrapper" style={{ margin: "5rem 0 3rem 0" }}>
                <Row>
                    <Col lg={3}>
                        <SummaryColumn>
                            <a
                                href="https://www.google.co.uk/maps/place/SW+Carpet+Cleaning/@51.4235818,-0.1751278,17z/data=!4m7!3m6!1s0x4876060f02d7bb93:0x555824b5943a4187!8m2!3d51.4235785!4d-0.1729391!9m1!1b1"
                                target="_blank"
                            >
                                <ReviewSummary>Excellent</ReviewSummary>
                                <FiveStars style={{ width: "80%", padding: "0.5rem" }} />
                                <ReviewCount>
                                    {this.state.companyData.rating} based on{" "}
                                    {this.state.companyData.user_ratings_total} reviews
                                </ReviewCount>
                                <GoogleLogo />
                            </a>
                        </SummaryColumn>
                    </Col>

                    <Col lg={9}>
                        <Slider {...reviewSliderSettings}>
                            {this.state.isLoaded
                                ? this.state.companyData.reviews.map((review, index) => (
                                      <ReviewWrap key={index}>
                                          <StarsDateWrap>
                                              {review.rating === 5 ? <FiveStars /> : <FourStars />}
                                              <Date>
                                                  <Moment format="YYYY/MM/DD" unix>
                                                      {review.time}
                                                  </Moment>
                                              </Date>
                                          </StarsDateWrap>
                                          <Review>
                                              {review.text.slice(0, 100)}
                                              {review.text.length > 110 ? "..." : ""}
                                          </Review>
                                          <Author>{review.author_name}</Author>
                                      </ReviewWrap>
                                  ))
                                : null}
                        </Slider>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default GoogleWidget;
