import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Testimonials.css";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageHeader from "../../components/PageHeader";
import Testimonial from "../../components/Testimonial";

import GoogleWidget from "../../components/GoogleWidget";

class TestimonialsPage extends Component {
  render() {
    return (
      <div className="testimonialsPageWrapper">
        <Helmet>
          <title>SW Carpet Cleaning - Testimonials</title>
          <meta name="description" content="SW Cartpet Cleaning - Testimonials" />
        </Helmet>

        <PageHeader imageId="quoteHeaderImg" name="Testimonials" />

        <Container className="pt-4">
          <Row>
            <Col lg={6} sm={12}>
              <Testimonial
                body="Jack did a great job today. Thank you."
                author="J Malan | Raynes Park, SW20"
              />
              <Testimonial
                body="I was very happy with my experience using Property People. They were most efficient, swift and helpful organizing and assisting with property maintenance."
                author="Donal Ward | Croydon, CR0"
              />
              <Testimonial
                body="I can highly recommend Property People. They provided me with an excellent service from start to finish and were efficient and professional at all times. Their approach is very personable and reliable, I would have no hesitation in using them again in the future."
                author="Amy Foy | Southfields, SW18"
              />
            </Col>
            <Col lg={6} sm={12}>
              <Testimonial
                body="Thanks again for doing such a great job! I don’t normally write testimonials but you were so efficient and the quality of work so high I thought I should! Can thoroughly recommend your services and was amazed at how quickly you quoted for the job and then saw it through to completion."
                author="D Wright, Yellow Printshop | Southfields, SW18"
              />
              <Testimonial
                body="I only use Property People for all my properties. They consistently offer a swift, effective service. Dedicated and guaranteed positive results. Flexible and competitively priced. Use Property People for honesty, transparency and professional integrity."
                author="G.Brown | Wandsworth Common, SW12"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>
                Property People always appreciate all feedback.
                <br />
                To send us feedback or submit a testimonial just email us here:
                info@property-people.net
              </h5>
            </Col>
          </Row>

          <GoogleWidget />
        </Container>
      </div>
    );
  }
}

export default withRouter(TestimonialsPage);
