import React, { useState } from "react";
import styled from "styled-components";
import PageHeader from "../../components/PageHeader";

const Title = styled.h3`
    color: var(--primary-blue);
`;
const Subtitle = styled.div`
    display: inline-flex;
    color: black;
    margin: 0 1rem 1rem 0;
    font-weight: 500;
    color: var(--primary-blue);
`;

const Body = styled.div`
    color: black;
    border-top: 1px solid var(--primary-blue);
    padding-top: 1rem;
    & * {
        font-family: "Roboto", sans-serif !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    & p * {
        font-size: 1rem !important;
    }
`;

const AdWrapper = styled.div`
    background: #e8f8ff;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
`;

export default function Careers({ jobAds }) {
    return (
        <>
            <PageHeader imageId="quoteHeaderImg" name="Careers" />
            {jobAds && (
                <div className="container mt-5">
                    <div className="row">
                        {jobAds.map((jobAd, index) => (
                            <div className="col-sm-12 col-lg-6">
                                <AdWrapper>
                                    <Title>{jobAd.title}</Title>
                                    <Subtitle>{jobAd.pay}</Subtitle>
                                    <Subtitle>{jobAd.type}</Subtitle>
                                    <Body>
                                        <div dangerouslySetInnerHTML={{ __html: jobAd.body }} />
                                    </Body>
                                </AdWrapper>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}
