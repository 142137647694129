import React, { Component } from 'react'
import {
    Link,
    withRouter
  } from 'react-router-dom'
import '../Blog.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Helmet from 'react-helmet'
import PageHeader from '../../../components/PageHeader'
import CallToAction from '../../../components/CallToAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

class MonstersInMyCarpetPage extends Component {

  async componentDidMount() {
    if(!document.getElementById("blog").classList.contains("activeMenu")){
      document.getElementById("blog").classList.add("activeMenu");
    }
    window.scroll(0, 0);
  }

  async componentWillUnmount() {
    document.getElementById("blog").classList.remove("activeMenu")
  }

  render() {
    return (
      <div className="articlePageWrapper">

      <Helmet>
        <title>The Monsters In My Carpet</title>
        <meta name="description" content="When was the last time you dropped a Jaffa cake or snickers on the carpet or mattress and then put it straight in your mouth? What do you imagine was attached to that?" />
      </Helmet>

      <PageHeader imageId="quoteHeaderImg" name="Blog"/>

      <Container>
        <Row>
          <Col>
            <h2 className="sectionHead">The Monsters In My Carpet</h2>
            <img className="articleHeroImg" src="/blog/viruses1.jpg" alt="The Monsters in My Carpet"/>
            <p className="article-big bold">When was the last time you dropped a Jaffa cake or snickers on the carpet or mattress and then put it straight in your mouth? What do you imagine was attached to that? Have you ever wondered if you were to take a sample of what is in your mattress or carpet, what you would find on a microscopic level?</p>
            <p className="article">Carpet & mattress are two of the most important and expensive investments in your home. Depending on when last you had them deep cleaned, it is just scary, the microscopic monsters that you will find lurking right under your feet living alongside your family in your beds and carpets.</p>
            <p className="article">Research by Philip Tierno, Jr., Ph.D., New York University Langone Medical shows that your carpet is 4,000 times grosser than your toilet. In fact there are around 200,000 bacteria living per square inch of your carpet.</p>
            <p className="article">Let’s take a closer look at what’s right under our feet  under the carpet and in our mattresses.</p>
            
            <h3 className="articleSubtitle">Methicillin Resistant Staphylococcus Aureus</h3>
            <img className="articleBodyImg" src="/blog/mrsa.jpg" alt="The Monsters in My Carpet"/>
            <p className="article pt-4">Say hello to your number 1 neighbour. MSRA is a super bacteria. Super because it is resistant to penicillin.</p>
            <p className="article">If you have any athletes in the house, they’ll bring MSRA home from the gym in that filthy gym bag that you realise you never wash or when dragging their dirty feet on the carpet that you haven’t had deep cleaned for years. Once in the carpet, it will be living alongside you while you play video games, lie on the carpet, watch TV and more.</p>
            <p className="article">MRSA can be carried by healthy people for just hours or many years. Hopefully you don’t get a cut. If you do it gets worse. It causes red bump, then fever and rashes. It can become untreatable, eventually leading to death.</p>
            
            <h3 className="articleSubtitle">Campylobacter</h3>
            <img className="articleBodyImg" src="/blog/campylobacter.jpg" alt="The Monsters in My Carpet"/>
            <p className="article pt-4">Campylobacter is most dangerous when it’s cold. Like most bacteria It thrives in dampness. It causes a potentially deadly disease known as campylobacteriosis, which can be extremely dangerous to people with a compromised immune system, like young children or the elderly. Symptoms include cramping, abdominal pain, bloody diarrhea and fever.</p>
            
            <h3 className="articleSubtitle">Norovirus</h3>
            <img className="articleBodyImg" src="/blog/norovirus.jpg" alt="The Monsters in My Carpet"/>
            <p className="article pt-4">Symptoms are much like food poisoning and the stomach flu. Norovirus is also called Norwalk virus. Norovirus can survive in your carpet or in your bed for over a month to six weeks. It becomes airborne when it comes in contact with people walking across it rolling over it. It’s even more dangerous in areas with a lot of dust.</p>
            
            <h3 className="articleSubtitle">Fungi and mold</h3>
            <img className="articleBodyImg" src="/blog/mold.jpg" alt="The Monsters in My Carpet"/>
            <p className="article pt-4">Alternaria, Aspergillus, Cladosporium, Geotrichum & Rhizopus to name a few that hide in your mattresses and carpets. All of these can cause serious health issues, allergies and even death.</p>
            
            <h3 className="articleSubtitle">Micrococcus</h3>
            <img className="articleBodyImg" src="/blog/micrococcus.jpg" alt="The Monsters in My Carpet"/>
            <p className="article pt-4">It can be a very nasty opportunistic pathogen, particularly in hosts with compromised immune systems, such as HIV patients.They may cause bad skin infections and other problems including recurrent bacteremia,  septic shock, septic arthritis, endocarditis, meningitis, and cavitating pneumonia.</p>

            <h3 className="articleSubtitle">Enterococcus</h3>
            <img className="articleBodyImg" src="/blog/enterococci.jpg" alt="The Monsters in My Carpet"/>
            <p className="article pt-4">Can cause serious infections in humans. Clinical infections caused by Enterococcus include urinary tract infections, bacteremia, bacterial endocarditis, diverticulitis, and meningitis.</p>
          </Col>
        </Row>

        <CallToAction />

        <Link to="blog">
          <FontAwesomeIcon icon={faAngleLeft}/> Back to blog
        </Link>

      </Container>
      
      </div>
    )
  }
}

export default withRouter (MonstersInMyCarpetPage)