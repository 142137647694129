import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./CallToAction.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

class CallToAction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <div className="ctaWrapper">
        <Row className="ctaTxt">
          <Col>
            <h3 className="cta">Call us now</h3>
            <FontAwesomeIcon icon={faPhoneAlt} className="faIconCta" />
            <h3 className="cta">0333 405 0580</h3>
          </Col>
        </Row>
        <Row className="ctaBtn">
          <Col>
            <Link to="quote">
              <button className="btn btn-primary">Instant Quote</button>
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(CallToAction);
