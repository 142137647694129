import React, { Component } from 'react'
import {
    Link,
    withRouter
  } from 'react-router-dom'
import '../Blog.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Helmet from 'react-helmet'
import PageHeader from '../../../components/PageHeader'
import CallToAction from '../../../components/CallToAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

class HowToDiyTipsForBeginnersPage extends Component {

  async componentDidMount() {
    document.getElementById("blog").classList.add("activeMenu");
    window.scroll(0, 0);
  }

  async componentWillUnmount() {
    document.getElementById("blog").classList.remove("activeMenu")
  }

  render() {
    return (
      <div className="articlePageWrapper">

      <Helmet>
        <title>How to… DIY tips for beginners</title>
        <meta name="description" content="Most people consider carpet deep cleaning when they notice that the carpet appears filthy and regular vacuum cleaning is not doing the trick. It’s most definitely the time for a deep carpet cleaning." />
      </Helmet>

      <PageHeader imageId="quoteHeaderImg" name="Blog"/>

      <Container>
        <Row>
          <Col>

            <h2 className="sectionHead">How to… DIY tips for beginners</h2>
            <img className="articleHeroImg" src="/blog/diy-tips.jpg" alt="The Monsters in My Carpet"/>
            <p className="article bold pt-4">Never hesitate to ask questions</p>
            <p className="article">Most people who are experienced in this job are great for asking about the projects. Initially I was very nervous, while asking question in hardware stores. Those days, I used to think that it may be stupid thing for some people. Today, it’s all right for me. “I am quite new in this project. How can I get this job done?” Though I know little bit about it but it is better to show that you do not know anything.</p>
            <p className="article bold">Capture photos</p>
            <p className="article">Most of the times I take pictures of everything that is broken prior to going to the hardware shop. Often I take original objects with me as well. It happened a lot of time with me that I tried to explain the real problem and bought a wrong object. In this case, a picture can help you a lot.</p>
            <p className="article bold">Start a project early in the day</p>
            <p className="article">You must avoid starting a project at the end of the day or during the evening. You may need to buy a tool or materials from a hardware store in order to get your project completed. If you have started working at evening, you are not going to get your required tools on time since hardware stores or shops would be shut by that time. So start your project early in the day.</p>
            <p className="article bold">Select Great Starter Projects</p>
            <p className="article">At starting level, you should select small projects for learning new skills and gradually start taking bigger projects. It was very helpful lesson for me. Small projects like changing the shower head, rewiring a lamp, assembling flat pack furniture and painting a small room will inspire you to move up and tackle complicated projects.</p>
            <p className="article bold">Tools</p>
            <p className="article">If you want to make your task really easy, you must buy some helpful set of tools. You must carefully store these tools in order to use them for next projects. Use a tool box and always use cover guards which come with tools such as saws, knives and chisel blades. Buy tools and equipment made to a British or European standard. Read instructions and warnings, especially if you are using a piece of equipment for the first time. Choose the right tool and protective equipment for the job.</p>
            <p className="article">If a job is too much, call in the professionals. We are here to help.</p>
            
          </Col>
        </Row>

        <CallToAction />

        <Link to="blog">
          <FontAwesomeIcon icon={faAngleLeft}/> Back to blog
        </Link>

      </Container>
      
      </div>
    )
  }
}

export default withRouter (HowToDiyTipsForBeginnersPage)