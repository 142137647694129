import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EditRounded, DeleteForever } from "@material-ui/icons";
import { publicRequest } from "../requestMethods";

const Wrapper = styled.div`
    margin-bottom: 1rem;
    margin-top: 2rem;
`;

const Title = styled.h3`
    color: var(--primary-blue);
`;
const Subtitle = styled.div`
    display: inline-flex;
    color: black;
    margin: 0 1rem 0.4rem 0;
    font-weight: 500;
    color: var(--primary-blue);
`;

const Body = styled.div`
    color: black;
    & * {
        font-family: "Roboto", sans-serif !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    & p * {
        font-size: 1rem !important;
    }
    & p {
        font-size: 1rem !important;
    }
    /* display: -webkit-box; */
    overflow: hidden;
    margin-bottom: 1rem;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
`;

const ShowAllBtn = styled.div`
    font-size: 0.9rem;
    display: inline-block;
    color: var(--primary-blue);
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: underline;
`;

const ButtonWrapper = styled.div`
    margin-top: 0.4rem;
`;

const EditRoundedBtn = styled(EditRounded)`
    color: var(--primary-blue);
    border-radius: 5px;
    background: #ebf3ff;
    padding: 0.25rem;
    transition: 0.3s;
    border: 1px solid #ffffff00;
    cursor: pointer;
    margin-right: 0.5rem;
    &:hover {
        border: 1px solid var(--primary-blue);
        transition: 0.3s;
    }
`;

const DeleteBtn = styled(DeleteForever)`
    color: #c9c9c9;
    border-radius: 5px;
    background: #f8f8f8;
    padding: 0.25rem;
    transition: 0.3s;
    border: 1px solid #ffffff00;
    cursor: pointer;
    &:hover {
        color: var(--warning);
        border: 1px solid var(--warning);
        transition: 0.3s;
    }
`;

export default function JobAdAdminList({ jobAd, setJobAdToEdit, handleModalOpen }) {
    const [isDeleted, setIsDeleted] = useState(false);

    const [showAll, setShowAll] = useState("-webkit-box");

    const handleDelete = async (e) => {
        e.preventDefault();
        try {
            await publicRequest.delete(`/jobads/${jobAd._id}`);
            setIsDeleted(true);
        } catch (error) {
            console.log(error);
        }
    };

    const handleEdit = (e) => {
        e.preventDefault();
        setJobAdToEdit(jobAd);
        handleModalOpen();
    };

    const handleShowAll = (e) => {
        showAll === "-webkit-box" ? setShowAll("block") : setShowAll("-webkit-box");
    };

    return (
        <>
            {!isDeleted && (
                <div className="row">
                    <div className="col">
                        <Wrapper>
                            <Title>{jobAd.title}</Title>
                            <Subtitle>{jobAd.pay}</Subtitle>
                            <Subtitle>{jobAd.type}</Subtitle>
                            <Body style={{ display: showAll }}>
                                <div dangerouslySetInnerHTML={{ __html: jobAd.body }} />
                            </Body>
                            <ButtonWrapper>
                                <ShowAllBtn onClick={handleShowAll}>
                                    {showAll === "block" ? "Show less" : "Show all"}{" "}
                                </ShowAllBtn>
                                <EditRoundedBtn onClick={handleEdit} />
                                <DeleteBtn onClick={handleDelete} />
                            </ButtonWrapper>
                        </Wrapper>
                    </div>
                </div>
            )}
        </>
    );
}
