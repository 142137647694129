import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "../Blog.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Helmet from "react-helmet";
import PageHeader from "../../../components/PageHeader";
import YouTube from "react-youtube";
import CallToAction from "../../../components/CallToAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

class HowOftenShouldIGetMyCarpetsCleanedPage extends Component {
  async componentDidMount() {
    document.getElementById("blog").classList.add("activeMenu");
    window.scroll(0, 0);
  }

  async componentWillUnmount() {
    document.getElementById("blog").classList.remove("activeMenu");
  }

  render() {
    return (
      <div className="articlePageWrapper">
        <Helmet>
          <title>How often should I get my carpets deep cleaned?</title>
          <meta
            name="description"
            content="Most people consider carpet deep cleaning when they notice that the carpet appears filthy and regular vacuum cleaning is not doing the trick. It’s most definitely the time for a deep carpet cleaning."
          />
        </Helmet>

        <PageHeader imageId="quoteHeaderImg" name="Blog" />

        <Container>
          <Row>
            <Col>
              <h2 className="sectionHead">
                How often should I get my carpets deep cleaned?
              </h2>
              <img
                className="articleHeroImg"
                src="/blog/how-often.jpg"
                alt="The Monsters in My Carpet"
                style={{ width: "60%" }}
              />
              <p className="article-big bold">
                Most people consider carpet deep cleaning when they notice that
                the carpet appears filthy and regular vacuum cleaning is not
                doing the trick. It’s most definitely the time for a deep carpet
                cleaning. In reality however that carpet was probably due a deep
                clean for months or years. Everyday traffic especially from pets
                and kids will deposit all sorts of filth in the carpets and will
                require regular professional hot water extraction carpet
                cleaning. How regular you use a professional hot water
                extraction carpet cleaning method will be dependent on the type
                of your carpet and its use in your home.
              </p>
              <p className="article">
                Just by a conservative estimate, if your carpet gets moderate
                foot traffic then you should consider having it cleaned at least
                once a year. You can go a little longer if you have
                infrequently-used carpets. If it’s a carpet that gets a lot of
                foot traffic then you should consider having your carpets deep
                cleaned at least every 3 months. This is especially true if
                respiratory-sensitive or allergic people live in the household,
                or if you have pets.
              </p>
              <p className="article">
                Here is a guide and with facts that should be considered when
                working out how often you need to have your carpets
                professionally cleaned with a hot water extraction method: The
                factors that determine the frequency of how often its required
                are: traffic, vacuuming and spot cleaning frequency, and soil
                level. Always follow the carpet manufacturer’s recommendations
                this will help you preserve the quality of your carpet and
                maintain a long life span.
              </p>
              <p className="article">
                Low foot traffic: If you vacuum your carpet once a week, you
                should have deep carpet cleaning once a year.
                <br />
                Medium foot traffic: If you have kids, you are probably
                vacuuming twice a week and therefore consider getting you
                carpets deep cleaned every 6 months.
                <br />
                Heavy foot traffic: If it’s a household with kid, pets and
                smokers then you would be vacuuming 3 to 4 times a week, and
                need to have your carpets cleaned every 3 months.
                <br />
                Extreme foot traffic: Large families with multiple pets will
                require daily vacuuming and deep carpet cleaning every 2 months.
              </p>
              <p className="article">
                Hot water extraction method of Deep carpet cleaning is best left
                in the hands of professionals. Call Property People today on
                0333 405 0580 to get advice. Our industrial carpet cleaning
                products and equipment are state of the art and have been
                independently tested to effectively clean carpet without
                damaging it. With Property People your carpets will be cleaned
                by qualified technicians and will also dry quicker since our
                equipment uses stronger extraction power.
              </p>
              <p className="article">
                Now that you have worked out how regularly you need to have your
                carpets cleaned, you are half way there to maintaining long
                life, hygienically and visually clean carpets. We can complement
                that knowledge by helping you prolong the life and quality of
                your home’s carpet with the help of our carpet technicians and
                commercial carpet cleaning, machines. Consider hiring a Property
                People professional carpet cleaner for your deep carpet
                cleaning, you will be amazed with the results
              </p>
              <p className="article bold">Conclusion:</p>
              <p className="article">
                In very low foot traffic areas, carpets can go up to a year
                between deep cleans, but in the case of high foot traffic spots,
                they may need to be professionally deep cleaned with a hot water
                extraction method, every three months. Homes with pets, smokers,
                children and an extreme foot traffic should have their carpets
                professionally deep cleaned four times a year.
              </p>
              <p className="article">
                We often use this video from the Richard & Judy show as a
                reference to back up our claims. If you take a few minutes to
                watch it you will see the horrific facts behind how dirty your
                carpet is at a microscopic level. Simply put, your carpet is
                probably 5 times dirtier than the street outside your front
                door. Scary!
              </p>

              <YouTube videoId="dTpjc-rXG0A" />
            </Col>
          </Row>

          <CallToAction />

          <Link to="blog">
            <FontAwesomeIcon icon={faAngleLeft} /> Back to blog
          </Link>
        </Container>
      </div>
    );
  }
}

export default withRouter(HowOftenShouldIGetMyCarpetsCleanedPage);
