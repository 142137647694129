import React, { Component } from 'react'
import {
    // Link,
    withRouter
  } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class PageHeader extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {}

  render() {
    return (
      <div className="pageHeader" id={this.props.imageId}>
          <Container>
              <Row>
                <Col>
                  <h1>{this.props.name}</h1>
                </Col>
              </Row>
          </Container>
      </div>
    )
  }
}

export default withRouter (PageHeader)