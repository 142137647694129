import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./Home.css";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GoogleWidget from "../../components/GoogleWidget";

class Home extends Component {
  render() {
    const heroSliderSettings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      // autoplay:true,
    };

    // const compareSliderSettings = {
    //   infinite: true,
    //   speed: 1000,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   arrows: true,
    //   autoplay:true,
    //   rtl:true,
    // }

    return (
      <div>
        <Helmet>
          <title>SW Carpet Cleaning - Home</title>
          <meta name="description" content="SW Cartpet Cleaning" />
        </Helmet>

        <div className="bannerSection">
          <Slider {...heroSliderSettings}>
            <div className="heroBanner firstBanner">
              <Container>
                <Row>
                  <Col className="ctaButtonWrapper">
                    <div className="getQuoteWrap">
                      <h2>GET A QUOTE NOW</h2>
                      <Link to="quote">
                        <button className="btn btn-primary">Residential</button>
                      </Link>
                      <Link to="quote-end-of-tenancy">
                        <button className="btn btn-primary middle-btn">End of Tenancy</button>
                      </Link>
                      <Link to="quote-commercial">
                        <button className="btn btn-primary">Commercial</button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            {/* <div className="heroBanner firstBanner">
              <Container>
                Text'n'stuff 2
                <Row>
                  <Col>
                    <button className="btn"></button>
                  </Col>
                </Row>
              </Container>
            </div> */}
          </Slider>
        </div>
        <Container>
          <Row>
            <Col md={12} lg={4} className="infoBox boxLight">
              <div className="titleBox">
                <h2 className="whoWeAre">Who We Are</h2>
              </div>
              <p>
                We are professional carpet cleaners serving the South West London and surroundings
                areas. We have a dedicated carpet cleaning focused team that provides affordable
                carpet cleaning services in London.
              </p>
            </Col>
            <Col md={12} lg={4} className="infoBox boxDark">
              <div className="titleBox">
                <h2 className="whatWeDo">What We Do</h2>
              </div>
              <p>
                We provide a complete range of carpet cleaning, rug cleaning services. Wondering if
                your carpet needs a deep clean? Click here to learn why its a must.
              </p>
            </Col>
            <Col md={12} lg={4} className="infoBox boxLight">
              <div className="titleBox">
                <h2 className="whereWeDo">Where We Do It</h2>
              </div>
              <p>
                We are based in Tooting high Street and cover most of South West London areas with a
                fast response time. We have clients all over London and are happy to discuss any job
                within greater London. Call us now to make an appointment – or use our handy chat
                feature!
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="covidBox">
                <a
                  href="./home/staying-covid-19-secure-2020-230720.pdf"
                  className="covidNotice"
                  target="_blank"
                >
                  Covid-19 Notice
                </a>
              </div>
            </Col>
          </Row>

          <Row
            className="pageSection"
            // style={{marginTop:"2rem"}}
          >
            <Col>
              <h2
                style={{
                  color: "var(--primary-blue)",
                  marginBottom: "1rem",
                }}
              >
                See immediate results after our visit!
              </h2>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <img
                className="fw-img"
                src={"./home/immediate-result-1.jpg"}
                alt="Immediate result after carpet clean"
              />
            </Col>
            <Col lg={6}>
              <img
                className="fw-img"
                src={"./home/immediate-result-2.jpg"}
                alt="Immediate result after carpet clean"
              />
            </Col>
          </Row>

          <Row className="pageSection">
            <Col md={12} lg={4} className="blogBox">
              <Link to="science-behind-deep-cleaning-carpets">
                <h3>The Science Behind Deep Cleaning</h3>
                <img
                  className="homeBlog"
                  draggable="false"
                  src={"./home/carpet-monsters.jpg"}
                  alt=""
                />
              </Link>
            </Col>
            <Col md={12} lg={4} className="blogBox">
              <Link to="hot-water-extraction-or-steam-cleaning">
                <h3>What is hot water extraction?</h3>
                <img
                  className="homeBlog"
                  draggable="false"
                  src={"./home/water-extraction.jpg"}
                  alt=""
                />
              </Link>
            </Col>
            <Col md={12} lg={4} className="blogBox">
              <Link to="monsters-in-my-carpet">
                <h3>What lives inside your carpet?</h3>
                <img className="homeBlog" draggable="false" src={"./home/what-lives.jpg"} alt="" />
              </Link>
            </Col>
          </Row>

          {/* OUR WORK START */}

          {/* <Row className="pageSection">
            <Col className="compareSection">
              <h2 className="sectionHead">Our Work</h2>
              <Slider {...compareSliderSettings}>
                <div className="compareImgWrap">
                  <div className="compareImg">
                    <img
                      className="homeBlog"
                      draggable='false'
                      src={'./home/compare-img-1.jpg'}
                      alt=""
                    />
                    <div className="beforeCompare">
                      Before
                    </div>
                  </div>

                  <div className="compareImg">
                    <img
                      className="homeBlog"
                      draggable='false'
                      src={'./home/compare-img-1.jpg'}
                      alt=""
                    />
                    <div className="afterCompare">
                      <FontAwesomeIcon 
                        icon={faPlay} 
                        className="faIconCompare" 
                      />
                      After
                    </div>
                  </div>
                </div>

                <div className="compareImgWrap">
                  <div className="compareImg">
                    <img
                      className="homeBlog"
                      draggable='false'
                      src={'./home/compare-img-1.jpg'}
                      alt=""
                    />
                    <div className="beforeCompare">
                      Before
                    </div>
                  </div>

                  <div className="compareImg">
                    <img
                      className="homeBlog"
                      draggable='false'
                      src={'./home/compare-img-1.jpg'}
                      alt=""
                    />
                    <div className="afterCompare">
                      <FontAwesomeIcon 
                        icon={faPlay} 
                        className="faIconCompare" 
                      />
                      After
                    </div>
                  </div>
                </div>
              </Slider>
            </Col>
          </Row> */}

          {/* OUR WORK END */}

          <GoogleWidget />
        </Container>
      </div>
    );
  }
}

export default withRouter(Home);
