import React, { Component, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import TopStoreNav from "./components/TopStoreNav";
import TopHeader from "./components/TopHeader";
import Footer from "./components/Footer";

// Pages
import Home from "./pages/Home/Home";
import ServicesPage from "./pages/Services/Services";
import CarpetCleaningPage from "./pages/Services/CarpetCleaning";
import RugCleaningPage from "./pages/Services/RugCleaning";
import MattressCleaningPage from "./pages/Services/MattressCleaning";
import BlogPage from "./pages/Blog/Blog";
import MonstersInMyCarpetPage from "./pages/Blog/Articles/MonstersInMyCarpet";
import ScienceBehindDeepCleaningCarpetsPage from "./pages/Blog/Articles/ScienceBehindDeepCleaningCarpets";
import HowOftenShouldIGetMyCarpetsCleanedPage from "./pages/Blog/Articles/HowOftenShouldIGetMyCarpetsCleaned";
import HotWaterExtractionOrSteamCleaningPage from "./pages/Blog/Articles/HotWaterExtractionOrSteamCleaning";
import HowToDiyTipsForBeginnersPage from "./pages/Blog/Articles/HowToDiyTipsForBeginners";
import TestimonialsPage from "./pages/Testimonials/Testimonials";
import ContactPage from "./pages/Contact/Contact";
import QuotePage from "./pages/Quote/Quote";
import QuoteEndOfTenancyPage from "./pages/Quote/QuoteEndOfTenancy";
import QuoteCommercial from "./pages/Quote/QuoteCommercial";
import NotFound from "./pages/NotFound/NotFound";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";

// Admin dasboard & related imports
import { publicRequest } from "./requestMethods";
import Auth from "./pages/Auth/Auth";
import Dashboard from "./pages/Dashboard/Dashboard";
import CareersPage from "./pages/Careers/Careers";

import { getSession } from "./utils";

export default function App() {
    const [jobAds, setJobAds] = useState();

    // Prefetch job ads for careers page to avoid loading delay
    useEffect(() => {
        const getJobAds = async () => {
            try {
                const res = await publicRequest.get("/jobads");
                setJobAds(res.data);
            } catch (error) {
                console.log(error);
            }
        };

        getJobAds();
    }, []);

    return (
        <Router>
            <TopStoreNav />
            <TopHeader />

            <Switch>
                <Route path="/services">
                    <ServicesPage />
                </Route>

                <Route path="/carpet-cleaning">
                    <CarpetCleaningPage />
                </Route>

                <Route path="/rug-cleaning">
                    <RugCleaningPage />
                </Route>

                <Route path="/mattress-cleaning">
                    <MattressCleaningPage />
                </Route>

                <Route path="/blog">
                    <BlogPage />
                </Route>

                <Route path="/monsters-in-my-carpet">
                    <MonstersInMyCarpetPage />
                </Route>

                <Route path="/science-behind-deep-cleaning-carpets">
                    <ScienceBehindDeepCleaningCarpetsPage />
                </Route>

                <Route path="/how-often-should-i-get-my-carpets-cleaned">
                    <HowOftenShouldIGetMyCarpetsCleanedPage />
                </Route>

                <Route path="/hot-water-extraction-or-steam-cleaning">
                    <HotWaterExtractionOrSteamCleaningPage />
                </Route>

                <Route path="/how-to-diy-tips-for-beginners">
                    <HowToDiyTipsForBeginnersPage />
                </Route>

                <Route path="/testimonials">
                    <TestimonialsPage />
                </Route>

                <Route path="/contact">
                    <ContactPage />
                </Route>

                <Route path="/quote">
                    <QuotePage />
                </Route>

                <Route path="/careers">
                    <CareersPage jobAds={jobAds} />
                </Route>

                <Route path="/quote-end-of-tenancy">
                    <QuoteEndOfTenancyPage />
                </Route>

                <Route path="/quote-commercial">
                    <QuoteCommercial />
                </Route>

                <Route path="/login">
                    <Auth />
                </Route>

                <Route path="/register">
                    <Auth />
                </Route>

                <PrivateRoute path="/dashboard" component={Dashboard} />

                <Route path="/">
                    <Home />
                </Route>

                <Route>
                    <NotFound />
                </Route>
            </Switch>

            <Footer />
        </Router>
    );
}

/**
 * A component to protect routes.
 * Shows Auth page if the user is not authenticated
 */
const PrivateRoute = ({ component, ...options }) => {
    const session = getSession();

    const finalComponent = session ? Dashboard : Home;
    return <Route {...options} component={finalComponent} />;
};
