import React, { Component } from 'react'
import {
    // Link,
    withRouter
  } from 'react-router-dom'
import './Services.css'
import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import PageHeader from '../../components/PageHeader'
import MattressCleaningService from '../../components/MattressCleaningService'
import CallToAction from '../../components/CallToAction'

class MattressCleaningPage extends Component {

  async componentDidMount() {
    document.getElementById("services").classList.add("activeMenu")
  }

  async componentWillUnmount() {
    document.getElementById("services").classList.remove("activeMenu")
  }

  render() {
    return (
      <div className="servicesPageWrapper">
      <PageHeader imageId="quoteHeaderImg" name="Mattress Cleaning"/>

      <Container>
        <MattressCleaningService />
        <CallToAction />
      </Container>
      
      </div>
    )
  }
}

export default withRouter (MattressCleaningPage)