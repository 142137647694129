import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faEnvelope, faBars } from "@fortawesome/free-solid-svg-icons";

class TopHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpacity: "0",
            menuXposition: "0",
            dropdownPointerEvents: "none",
            mobMenuPointerEvents: "none",
            mobMenu: "0",
        };
        this.handleDropdownEnter = this.handleDropdownEnter.bind(this);
        this.handleDropdownLeave = this.handleDropdownLeave.bind(this);
        this.handleDropdownClick = this.handleDropdownClick.bind(this);
        this.handleMobMenuToggle = this.handleMobMenuToggle.bind(this);
    }

    handleMobMenuToggle() {
        this.state.mobMenu === "0"
            ? this.setState({ mobMenu: "1", mobMenuPointerEvents: "all" })
            : this.setState({ mobMenu: "0", mobMenuPointerEvents: "none" });
    }

    handleDropdownEnter() {
        this.setState({
            menuOpacity: "1",
            menuXposition: document.getElementById("services").getBoundingClientRect().x,
            dropdownPointerEvents: "all",
        });
    }

    handleDropdownLeave() {
        this.setState({
            menuOpacity: "0",
            dropdownPointerEvents: "none",
        });
    }

    handleDropdownClick() {
        this.setState({
            menuOpacity: "0",
            dropdownPointerEvents: "none",
        });
    }

    async componentDidMount() {
        this.setState({
            menuXposition: document.getElementById("services").getBoundingClientRect().x,
        });
    }

    render() {
        const MenuItems = [
            { name: "Home", slug: "" },
            { name: "Services", slug: "services" },
            { name: "Blog", slug: "blog" },
            { name: "Testimonials", slug: "testimonials" },
            { name: "Contact", slug: "contact" },
            { name: "Quote Me", slug: "quote" },
            { name: "Careers", slug: "careers" },
        ];

        const subMenuItems = [
            {
                title: "Carpet Cleaning",
                subtitle: "Hot Water Extraction",
                slug: "carpet-cleaning",
            },
            {
                title: "Rug Cleaning",
                subtitle: "Hot Water Extraction",
                slug: "rug-cleaning",
            },
            {
                title: "Mattress Cleaning",
                subtitle: "Deep Clean",
                slug: "mattress-cleaning",
            },
        ];

        const ListSubMenuItems = subMenuItems.map((item) => (
            <div
                className="subMenuWrap"
                onMouseEnter={this.handleDropdownEnter}
                key={item.title}
                onMouseLeave={this.handleDropdownLeave}
                onClick={this.handleDropdownClick}
            >
                <Link to={item.slug}>
                    <div className="itemTitle">{item.title}</div>
                    <div className="itemSubtitle">{item.subtitle}</div>
                </Link>
            </div>
        ));

        const ListMenuItems = MenuItems.map((item) => (
            <Col
                key={item.slug}
                id={item.slug}
                className={
                    `/${item.slug}` === this.props.location.pathname
                        ? "menuItem activeMenu"
                        : "menuItem"
                }
                onMouseEnter={item.name === "Services" ? this.handleDropdownEnter : undefined}
                onMouseLeave={item.name === "Services" ? this.handleDropdownLeave : undefined}
                onClick={this.handleMobMenuToggle}
            >
                <Link to={item.slug} className="topMenuLink">
                    <li>{item.name}</li>
                </Link>
            </Col>
        ));

        return (
            <div className="headerWrapepr">
                <div className="header">
                    <Container>
                        <Row>
                            <Col>
                                <Link to="/">
                                    <img
                                        className="headerLogo"
                                        draggable="false"
                                        src={"./common/sw-carpet-cleaning-logo.png"}
                                        alt="SW Carpet Cleaning"
                                    />
                                </Link>
                                <div className="burgerBtn" onClick={this.handleMobMenuToggle}>
                                    <FontAwesomeIcon icon={faBars} className="burgerIcon" />
                                </div>
                            </Col>
                            <Col className="headerContact">
                                <div>
                                    <a href="tel:03334050580">
                                        <FontAwesomeIcon icon={faPhoneAlt} className="faIcon" />
                                        0333 405 0580
                                    </a>
                                </div>
                                <div>
                                    <a href="mailto:info@swcarpetcleaning.co.uk">
                                        <FontAwesomeIcon icon={faEnvelope} className="faIcon" />
                                        info@swcarpetcleaning.co.uk
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Desktop Menu */}
                <div className="topMenu">
                    <Container>
                        <Row>{ListMenuItems}</Row>
                        <Row>
                            <div
                                className="menuDropDown"
                                style={{
                                    opacity: this.state.menuOpacity,
                                    left: this.state.menuXposition,
                                    pointerEvents: this.state.dropdownPointerEvents,
                                }}
                            >
                                {ListSubMenuItems}
                            </div>
                        </Row>
                    </Container>
                </div>

                {/* Mobile Menu */}
                <div
                    className="mobMenu"
                    style={{
                        opacity: `${this.state.mobMenu}`,
                        pointerEvents: this.state.mobMenuPointerEvents,
                    }}
                >
                    <Container fluid className="mobMenuContainer">
                        {ListMenuItems}
                    </Container>
                </div>
            </div>
        );
    }
}

export default withRouter(TopHeader);
