import React, { Component } from "react";
import {
  // Link,
  withRouter,
} from "react-router-dom";
import axios from "axios";
import "./Quote.css";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageHeader from "../../components/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import ModalPopup from "../../components/ModalPopup";
import QuoteSwitch from "../../components/QuoteSwitch";

class QuotePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertySize: "139",
      stairs: "0",
      reception: "0",
      furniture: "0",
      vacuum: "0",
      pretreatment: "0",
      phSample: "0",
      rugs: "0",
      professional: "0",
      bathrooms: "0",
      info: "",
      infoLeft: "",
      infoTop: "",
      infoOpacity: "0",
      name: "Not provided",
      email: "",
      phone: "Not provided",
      address: "Not provided",
      discount: 1,
      quoteTotal: 0,
      thanksOpacity: 0,
      emailBtnOpacity: 1,
      emailBtnPointerEvents: "all",
      emailValidation: "",
      modalOpacity: 0,
      modalPointerEvents: "none",
      noDiscountQuoteOpacity: 0,
      contactChecked: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onCheckboxSelect = this.onCheckboxSelect.bind(this);
  }

  onNameChange(e) {
    this.setState({ name: e.target.value });
  }

  onEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  onPhoneChange(e) {
    if (isNaN(e.target.value)) {
      console.log("Please use numbers only"); // Handle phone number validation
    } else {
      this.setState({ phone: e.target.value });
    }
  }

  onAddressChange(e) {
    this.setState({ address: e.target.value });
  }

  onCheckboxSelect() {
    this.state.contactChecked
      ? this.setState({ contactChecked: false })
      : this.setState({ contactChecked: true });
    // console.log(this.state.contactChecked)
  }

  handleSubmit(e) {
    e.preventDefault();

    if (!this.state.email.includes("@" && ".")) {
      // Handle email validation
      this.setState({ emailValidation: "Please enter a valid email address" });
    } else {
      this.setState({
        emailBtnOpacity: 0,
        thanksOpacity: 1,
        emailBtnPointerEvents: "none",
        emailValidation: "",
      });

      let quoteTotal =
        (parseFloat(this.state.propertySize) +
          parseFloat(this.state.stairs) +
          parseFloat(this.state.reception) +
          parseFloat(this.state.furniture) +
          parseFloat(this.state.vacuum) +
          parseFloat(this.state.pretreatment) +
          parseFloat(this.state.phSample) +
          parseFloat(this.state.bathrooms) +
          parseFloat(this.state.rugs) +
          parseFloat(this.state.professional)) *
        this.state.discount;

      let txt = {
        propertysize:
          "Property size: " + document.getElementById("propertySize").selectedOptions[0].text,
        stairs:
          "Sets of stairs or landings: " +
          document.getElementById("setsOfStairs").selectedOptions[0].text,
        reception:
          "Reception rooms / Dining rooms carpeted: " +
          document.getElementById("roomsCarpeted").selectedOptions[0].text,
        furniture:
          "Furniture moving: " + document.getElementById("furnitureMoving").selectedOptions[0].text,
        vacuum:
          "Carpet needs to be vacuumed: " +
          document.getElementById("vacuumCleaning").selectedOptions[0].text,
        pretreatment:
          "Pre-treatment: " + document.getElementById("pretreatment").selectedOptions[0].text,
        phSample:
          "Take pH balance sample: " + document.getElementById("phSample").selectedOptions[0].text,
        rugs: "Rugs: " + document.getElementById("rugs").selectedOptions[0].text,
        professional:
          "Professional clean: " +
          document.getElementById("professionalClean").selectedOptions[0].text,
        bathrooms:
          "Bathrooms or toilets: " + document.getElementById("bathrooms").selectedOptions[0].text,
      };

      let emailToMe = {
        fromEmail: "info@swcarpetcleaning.co.uk",
        toEmail: "info@swcarpetcleaning.co.uk",
        subject: `New quote request from ${this.state.name}`,
        body: `<div style="font-size:18px; line-height:1.5;">
              Name: <b>${this.state.name} </b><br/>
              Email: <b>${this.state.email} </b><br/>
              Phone: <b>${this.state.phone} </b><br/>
              Address: <b>${this.state.address} </b><br/>
              Quote amount: <b>£${quoteTotal} </b> (${txt.propertysize}, ${txt.stairs}, ${
          txt.reception
        }, ${txt.furniture}, ${txt.vacuum}, ${txt.rugs}, ${txt.professional}, ${txt.bathrooms})<br/>
              Contact now to book an appointment? <b>${
                this.state.contactChecked ? "Yes" : "No"
              } </b><br/>
              </div>
              <div style="font-size:14px; padding-top:12px">
                <i>This form was sent using swcarpetcleaning.co.uk quote form.</i>
              </div>`,
      };

      let emailToUser = {
        fromEmail: "info@swcarpetcleaning.co.uk",
        toEmail: `${this.state.email}`,
        subject: `Thank you for interest in our services`,
        body: `<div style="font-size:16px; line-height:1.5;">

      <p>Hello ${this.state.name},</p>
      <p>Thank you for interest in our services. Here is your quote: <b>£${quoteTotal}</b>
      (${txt.propertysize}, ${txt.stairs}, ${txt.reception}, ${txt.furniture}, ${txt.vacuum}, ${txt.pretreatment}, ${txt.phSample}, ${txt.rugs}, ${txt.professional}, ${txt.bathrooms})</p>
      <p>This quote is tailored in such a way that allows you to get value for money by doing some of the work yourself like moving furniture or vacuuming beforehand. Or you can leave it all to us. Either way, you will be surprised by the results.</p>
      <p> What happens next?</p>
      <ul>
        <li>If you have checked “Contact me now to book an appointment” we will call you shortly to do exactly that.
        <li>If you were simply curious about our prices, that is fine too. We may follow up with an email to check if you were still interested and if there is anything, we can do to win your business.</li>
      </ul>
      <p>Here at SW Carpet Cleaning we use state of the art hot water extraction carpet cleaning machines. These are not your average DIY machines.  Our machines utilise industrial powerful 2-stage vacuum and over 150psi pumps to suck out even the most stubborn of compacted dust and debris that is buried deep in your carpet.</li>
      <p>Our staff are highly trained professionals trained by the National Association of Carpet Cleaners and regularly go through additional extensive training to keep up with the industry standards.</p>
      <p>We look forward to seeing you soon and leaving you with fresh smelling clean carpet.</p>
      <br>
      <p>Kind Regards</p>
      <p>SW Carpet Cleaning Team.</p>
      <p>P.S. Ask us about our subscription service. This is where we can offer you discounted price and clean you carpet bi-annually or quarterly at a discounted rate.</p>

      </div>
      <div style="font-size:14px; padding-top:12px">
        <i>This form was sent using swcarpetcleaning.co.uk quote form.</i>
      </div>`,
      };

      axios
        .post(
          `https://4088ea0788.execute-api.us-east-1.amazonaws.com/default/contactFormSend`,
          emailToMe
        )
        .then((res) => {
          // console.log(res);
          // console.log(res.data)
          // console.log(emailToUser)
          console.log("Email Sent");
        });

      axios
        .post(
          `https://4088ea0788.execute-api.us-east-1.amazonaws.com/default/contactFormSend`,
          emailToUser
        )
        .then((res) => {
          // console.log(res);
          // console.log(res.data)
          // console.log(emailToUser)
          console.log("Email Sent");
        });
    }
  }

  handleModal() {
    this.setState({
      discount: 0.9,
      modalOpacity: 0,
      modalPointerEvents: "none",
      noDiscountQuoteOpacity: 1,
    });
  }

  closeModal() {
    this.setState({
      modalOpacity: 0,
      modalPointerEvents: "none",
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handlePropertyChange(e) {
    this.setState({
      propertySize: e.target.value,
    });

    if (e.target.value === "139" && this.state.professional !== "0") {
      this.setState({ professional: "60" });
    } else if (e.target.value === "169" && this.state.professional !== "0") {
      this.setState({ professional: "60" });
    } else if (e.target.value === "199" && this.state.professional !== "0") {
      this.setState({ professional: "90" });
    } else if (e.target.value === "229" && this.state.professional !== "0") {
      this.setState({ professional: "120" });
    } else if (e.target.value === "259" && this.state.professional !== "0") {
      this.setState({ professional: "142.50" });
    } else if (e.target.value === "299" && this.state.professional !== "0") {
      this.setState({ professional: "172.50" });
    } else if (e.target.value === "339" && this.state.professional !== "0") {
      this.setState({ professional: "202.50" });
    } else if (e.target.value === "379" && this.state.professional !== "0") {
      this.setState({ professional: "232.50" });
    } else if (e.target.value === "429" && this.state.professional !== "0") {
      this.setState({ professional: "252.50" });
    }

    if (e.target.value === "139" && this.state.vacuum !== "0") {
      this.setState({ vacuum: "20" });
    } else if (e.target.value === "169" && this.state.vacuum !== "0") {
      this.setState({ vacuum: "20" });
    } else if (e.target.value === "199" && this.state.vacuum !== "0") {
      this.setState({ vacuum: "30" });
    } else if (e.target.value === "229" && this.state.vacuum !== "0") {
      this.setState({ vacuum: "40" });
    } else if (e.target.value === "259" && this.state.vacuum !== "0") {
      this.setState({ vacuum: "47.50" });
    } else if (e.target.value === "299" && this.state.vacuum !== "0") {
      this.setState({ vacuum: "57.50" });
    } else if (e.target.value === "339" && this.state.vacuum !== "0") {
      this.setState({ vacuum: "67.50" });
    } else if (e.target.value === "379" && this.state.vacuum !== "0") {
      this.setState({ vacuum: "77.50" });
    } else if (e.target.value === "429" && this.state.vacuum !== "0") {
      this.setState({ vacuum: "87.50" });
    }

    if (e.target.value === "139" && this.state.furniture !== "0") {
      this.setState({ furniture: "20" });
    } else if (e.target.value === "169" && this.state.furniture !== "0") {
      this.setState({ furniture: "20" });
    } else if (e.target.value === "199" && this.state.furniture !== "0") {
      this.setState({ furniture: "30" });
    } else if (e.target.value === "229" && this.state.furniture !== "0") {
      this.setState({ furniture: "40" });
    } else if (e.target.value === "259" && this.state.furniture !== "0") {
      this.setState({ furniture: "47.50" });
    } else if (e.target.value === "299" && this.state.furniture !== "0") {
      this.setState({ furniture: "57.50" });
    } else if (e.target.value === "339" && this.state.furniture !== "0") {
      this.setState({ furniture: "67.50" });
    } else if (e.target.value === "379" && this.state.furniture !== "0") {
      this.setState({ furniture: "77.50" });
    } else if (e.target.value === "429" && this.state.furniture !== "0") {
      this.setState({ furniture: "87.50" });
    }

    if (e.target.value === "139" && this.state.pretreatment !== "0") {
      this.setState({ pretreatment: parseFloat(e.target.value) / 2 });
    } else if (e.target.value === "169" && this.state.pretreatment !== "0") {
      this.setState({ pretreatment: parseFloat(e.target.value) / 2 });
    } else if (e.target.value === "199" && this.state.pretreatment !== "0") {
      this.setState({ pretreatment: parseFloat(e.target.value) / 2 });
    } else if (e.target.value === "229" && this.state.pretreatment !== "0") {
      this.setState({ pretreatment: parseFloat(e.target.value) / 2 });
    } else if (e.target.value === "259" && this.state.pretreatment !== "0") {
      this.setState({ pretreatment: parseFloat(e.target.value) / 2 });
    } else if (e.target.value === "299" && this.state.pretreatment !== "0") {
      this.setState({ pretreatment: parseFloat(e.target.value) / 2 });
    } else if (e.target.value === "339" && this.state.pretreatment !== "0") {
      this.setState({ pretreatment: parseFloat(e.target.value) / 2 });
    } else if (e.target.value === "379" && this.state.pretreatment !== "0") {
      this.setState({ pretreatment: parseFloat(e.target.value) / 2 });
    } else if (e.target.value === "429" && this.state.pretreatment !== "0") {
      this.setState({ pretreatment: parseFloat(e.target.value) / 2 });
    }

    if (e.target.value === "169" && this.state.phSample !== "0") {
      this.setState({ phSample: parseFloat(e.target.value) * 0.1 });
    } else if (e.target.value === "169" && this.state.phSample !== "0") {
      this.setState({ phSample: parseFloat(e.target.value) * 0.1 });
    } else if (e.target.value === "199" && this.state.phSample !== "0") {
      this.setState({ phSample: parseFloat(e.target.value) * 0.1 });
    } else if (e.target.value === "229" && this.state.phSample !== "0") {
      this.setState({ phSample: parseFloat(e.target.value) * 0.1 });
    } else if (e.target.value === "259" && this.state.phSample !== "0") {
      this.setState({ phSample: parseFloat(e.target.value) * 0.1 });
    } else if (e.target.value === "299" && this.state.phSample !== "0") {
      this.setState({ phSample: parseFloat(e.target.value) * 0.1 });
    } else if (e.target.value === "339" && this.state.phSample !== "0") {
      this.setState({ phSample: parseFloat(e.target.value) * 0.1 });
    } else if (e.target.value === "379" && this.state.phSample !== "0") {
      this.setState({ phSample: parseFloat(e.target.value) * 0.1 });
    } else if (e.target.value === "429" && this.state.phSample !== "0") {
      this.setState({ phSample: parseFloat(e.target.value) * 0.1 });
    }
  }

  handleHover(e) {
    this.setState({
      infoTop: e.target.offsetTop + 280,
      infoLeft: e.target.getBoundingClientRect().x + 25,
      infoOpacity: 1,
    });

    switch (e.target.getAttribute("name")) {
      case "propertySize":
        this.setState({
          info: "The bedrooms are the first rooms that we use to determine the size of the property and the related cost of carpet cleaning. We count the Lounge/living/reception/dining rooms as separate as sometimes those are laminated. This way if these rooms are laminated, you avoid paying more overall for you carpet cleaning.",
        });
        break;
      case "setsOfStairs":
        this.setState({
          info: "Each sets of stairs that lead to an additional floor would cost £25 each. If its just a few steps on the same floor leading to a raised area, select N/A",
        });
        break;
      case "roomsCarpeted":
        this.setState({
          info: "Sometimes Lounge/living/reception/dining rooms are laminated. But if yours is carpeted it would cost £25 for each of these additional carpeted rooms. Select N/A if they are not carpeted and do require carpet cleaning. Otherwise select the number of these additional rooms.",
        });
        break;
      case "furnitureMoving":
        this.setState({
          info: "Carpets under furniture usually only require vacuuming and that should be sufficient cleaning. Dust and debris do not gather and get compounded in carpet under furniture like other heavy use walked on areas of the carpet. If you would still like us to move furniture and steam clean those areas, the cost would be 25% of base rate of question 1",
        });
        break;
      case "vacuumCleaning":
        this.setState({
          info: "If the carpet is already vacuumed your quote will be lower. We can vacuum it for you of course, prior to steam cleaning at 25% of the base price in question 1.",
        });
        break;
      case "professionalClean":
        this.setState({
          info: "It may be the case that you also want to have the rest of the property to be cleaned. Maybe because it’s the end of a tenancy and the property need to be re-let.  We can do this of course at 75% additional of the base price in question 1.",
        });
        break;
      case "bathrooms":
        this.setState({
          info: "Toilets & bathrooms require extra care and if you want an end of tenancy clean, the quote will need to be tailored according to how many separate toilets, bathrooms/wet rooms/shower rooms you have. It will be £25 for each one.",
        });
        break;
      case "rugs":
        this.setState({ info: "It will be £29 additionally for each rug" });
        break;
      case "phSample":
        this.setState({
          info: "We will measure PH level of carpet in a few places before and after carpet cleaning to ensure the carpet is left with a neutral PH safe for children and pets. The cost would be 10 % of base rate of question 1",
        });
        break;
      case "pretreatment":
        this.setState({
          info: "We will spray all of the carpet with a pre-treatment chemical & use a brush or rake to agitate it. This will loosen any other particles that are clinging to it such as dirt, sweet drinks, milk, food and oil, prior to being cleaned. This makes it easier for the carpet to be rid of excess dirt and germs that are compounded down over the years and might have otherwise been missed. Carpet can take a lot of wear and tear and getting it truly clean can be a time-intensive process that takes numerous steps. The cost would be 50% of base rate of question 1",
        });
        break;
      default:
        console.log("defs");
    }
  }

  handleMouseOut() {
    this.setState({
      infoOpacity: 0,
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    setTimeout(() => {
      this.setState({
        modalOpacity: 1,
        modalPointerEvents: "all",
      });
    }, 120000);
  }

  render() {
    let noDiscountQuote =
      parseFloat(this.state.propertySize) +
      parseFloat(this.state.stairs) +
      parseFloat(this.state.reception) +
      parseFloat(this.state.furniture) +
      parseFloat(this.state.vacuum) +
      parseFloat(this.state.pretreatment) +
      parseFloat(this.state.phSample) +
      parseFloat(this.state.rugs) +
      parseFloat(this.state.professional) +
      parseFloat(this.state.bathrooms);

    let quote = noDiscountQuote * this.state.discount;

    let furnitureValue = "0";
    let vacuumValue = "0";
    let pretreatmentValue = "0";
    let phValue = "0";
    let profCleanValue = "0";

    if (this.state.propertySize === "139") {
      furnitureValue = "20";
      vacuumValue = "20";
      pretreatmentValue = parseFloat(this.state.propertySize) / 2;
      profCleanValue = "60";
      phValue = parseFloat(this.state.propertySize) * 0.1;
    } else if (this.state.propertySize === "169") {
      furnitureValue = "20";
      vacuumValue = "20";
      pretreatmentValue = parseFloat(this.state.propertySize) / 2;
      profCleanValue = "60";
      phValue = parseFloat(this.state.propertySize) * 0.1;
    } else if (this.state.propertySize === "199") {
      furnitureValue = "30";
      vacuumValue = "30";
      pretreatmentValue = parseFloat(this.state.propertySize) / 2;
      profCleanValue = "90";
      phValue = parseFloat(this.state.propertySize) * 0.1;
    } else if (this.state.propertySize === "229") {
      furnitureValue = "40";
      vacuumValue = "40";
      pretreatmentValue = parseFloat(this.state.propertySize) / 2;
      profCleanValue = "120";
      phValue = parseFloat(this.state.propertySize) * 0.1;
    } else if (this.state.propertySize === "259") {
      furnitureValue = "47.50";
      vacuumValue = "47.50";
      pretreatmentValue = parseFloat(this.state.propertySize) / 2;
      profCleanValue = "142.50";
      phValue = parseFloat(this.state.propertySize) * 0.1;
    } else if (this.state.propertySize === "299") {
      furnitureValue = "57.50";
      vacuumValue = "57.50";
      pretreatmentValue = parseFloat(this.state.propertySize) / 2;
      profCleanValue = "172.50";
      phValue = parseFloat(this.state.propertySize) * 0.1;
    } else if (this.state.propertySize === "339") {
      furnitureValue = "67.50";
      vacuumValue = "67.50";
      pretreatmentValue = parseFloat(this.state.propertySize) / 2;
      profCleanValue = "202.50";
      phValue = parseFloat(this.state.propertySize) * 0.1;
    } else if (this.state.propertySize === "379") {
      furnitureValue = "77.50";
      vacuumValue = "77.50";
      pretreatmentValue = parseFloat(this.state.propertySize) / 2;
      profCleanValue = "232.50";
      phValue = parseFloat(this.state.propertySize) * 0.1;
    } else if (this.state.propertySize === "429") {
      furnitureValue = "87.50";
      vacuumValue = "87.5";
      pretreatmentValue = parseFloat(this.state.propertySize) / 2;
      profCleanValue = "262.50";
      phValue = parseFloat(this.state.propertySize) * 0.1;
    }

    return (
      <div className="quotePageWrapper">
        <Helmet>
          <title>SW Carpet Cleaning - Residential Carpet Clean Quote</title>
          <meta name="description" content="SW Cartpet Cleaning -Residential Carpet Clean Quote" />
        </Helmet>

        <PageHeader imageId="quoteHeaderImg" name="Residential Carpet Clean Quote" />
        <Container>
          <Row>
            <Col>
              <QuoteSwitch />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Tailor your carpet cleaning quote</h2>

              <label name="propertySize">
                Property size
                <div
                  className="questionMarkW"
                  name="propertySize"
                  onMouseEnter={this.handleHover}
                  onMouseLeave={this.handleMouseOut}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} className="questionMark" />
                </div>
              </label>
              <select
                name="propertySize"
                id="propertySize"
                onChange={this.handlePropertyChange.bind(this)}
              >
                <option value="139">Studio £139</option>
                <option value="169">1 Bed £169</option>
                <option value="199">2 Bed £199</option>
                <option value="229">3 Bed £229</option>
                <option value="259">4 Bed £259</option>
                <option value="299">5 Bed £299</option>
                <option value="339">6 Bed £339</option>
                <option value="379">7 Bed £379</option>
                <option value="429">8 Bed £429</option>
              </select>

              <label name="setsOfStairs">
                Sets of stairs or Landings
                <div
                  className="questionMarkW"
                  name="setsOfStairs"
                  onMouseEnter={this.handleHover}
                  onMouseLeave={this.handleMouseOut}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} className="questionMark" />
                </div>
              </label>
              <select name="stairs" id="setsOfStairs" onChange={this.handleChange}>
                <option value="0">N/A</option>
                <option value="25">1</option>
                <option value="50">2</option>
                <option value="75">3</option>
                <option value="100">4</option>
                <option value="125">5</option>
                <option value="150">6</option>
                <option value="175">7</option>
                <option value="200">8</option>
              </select>

              <label name="roomsCarpeted">
                Reception rooms / Dining rooms carpeted?
                <div
                  className="questionMarkW"
                  name="roomsCarpeted"
                  onMouseEnter={this.handleHover}
                  onMouseLeave={this.handleMouseOut}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} className="questionMark" />
                </div>
              </label>

              <select name="reception" id="roomsCarpeted" onChange={this.handleChange}>
                <option value="0">N/A</option>
                <option value="25">1</option>
                <option value="50">2</option>
                <option value="75">3</option>
                <option value="100">4</option>
                <option value="125">5</option>
                <option value="150">6</option>
                <option value="175">7</option>
                <option value="200">8</option>
              </select>

              <label name="furnitureMoving">
                Furniture moving?
                <div
                  className="questionMarkW"
                  name="furnitureMoving"
                  onMouseEnter={this.handleHover}
                  onMouseLeave={this.handleMouseOut}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} className="questionMark" />
                </div>
              </label>
              <select name="furniture" id="furnitureMoving" onChange={this.handleChange}>
                <option value="0">No</option>
                <option value={furnitureValue}>Yes</option>
              </select>

              <label name="vacuumCleaning">
                Carpet needs to be vacuumed?
                <div
                  className="questionMarkW"
                  name="vacuumCleaning"
                  onMouseEnter={this.handleHover}
                  onMouseLeave={this.handleMouseOut}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} className="questionMark" />
                </div>
              </label>
              <select name="vacuum" id="vacuumCleaning" onChange={this.handleChange}>
                <option value="0">No</option>
                <option value={vacuumValue}>Yes</option>
              </select>

              {/* <label name="pretreatment">
                Pre-treatment (recommended for heavily soiled carpets)
                <div
                  className="questionMarkW"
                  name="pretreatment"
                  onMouseEnter={this.handleHover}
                  onMouseLeave={this.handleMouseOut}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} className="questionMark" />
                </div>
              </label>
              <select name="pretreatment" id="pretreatment" onChange={this.handleChange}>
                <option value="0">No</option>
                <option value={pretreatmentValue}>Yes</option>
              </select> */}

              {/* <label name="phSample">
                Take pH balance sample before and after
                <div
                  className="questionMarkW"
                  name="phSample"
                  onMouseEnter={this.handleHover}
                  onMouseLeave={this.handleMouseOut}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} className="questionMark" />
                </div>
              </label>
              <select name="phSample" id="phSample" onChange={this.handleChange}>
                <option value="0">No</option>
                <option value={phValue}>Yes</option>
              </select> */}

              <label name="rugs">
                Do you need any rugs cleaned, if so how many?
                <div
                  className="questionMarkW"
                  name="rugs"
                  onMouseEnter={this.handleHover}
                  onMouseLeave={this.handleMouseOut}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} className="questionMark" />
                </div>
              </label>
              <select name="rugs" id="rugs" onChange={this.handleChange}>
                <option value="0">N/A</option>
                <option value="29">1</option>
                <option value="58">2</option>
                <option value="87">3</option>
                <option value="116">4</option>
                <option value="145">5</option>
                <option value="174">6</option>
                <option value="203">7</option>
                <option value="232">8</option>
              </select>

              <label name="professionalClean">
                Professional clean of the rest of the property?
                <div
                  className="questionMarkW"
                  name="professionalClean"
                  onMouseEnter={this.handleHover}
                  onMouseLeave={this.handleMouseOut}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} className="questionMark" />
                </div>
              </label>
              <select name="professional" id="professionalClean" onChange={this.handleChange}>
                <option value="0">No</option>
                <option value={profCleanValue}>Yes</option>
              </select>

              <label name="bathrooms">
                If professional clean required, how many separate bathrooms and toilets?
                <div
                  className="questionMarkW"
                  name="bathrooms"
                  onMouseEnter={this.handleHover}
                  onMouseLeave={this.handleMouseOut}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} className="questionMark" />
                </div>
              </label>
              <select name="bathrooms" id="bathrooms" onChange={this.handleChange}>
                <option value="0">N/A</option>
                <option value="25">1</option>
                <option value="50">2</option>
                <option value="75">3</option>
                <option value="100">4</option>
                <option value="125">5</option>
                <option value="150">6</option>
                <option value="175">7</option>
                <option value="200">8</option>
              </select>

              <h2 className="sectionHeadAlt">Your quote is:</h2>
              <div className="quoteTotal">
                £{quote.toFixed(2)}
                <span className="oldQuote" style={{ opacity: this.state.noDiscountQuoteOpacity }}>
                  £{noDiscountQuote.toFixed(2)}
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Your Details</h2>
            </Col>
          </Row>

          {/* Submit form */}
          <form id="quoteForm" className="quoteForm" onSubmit={this.handleSubmit} noValidate>
            <Row>
              <Col sm={12} md={4}>
                <label htmlFor="Name">Name*</label>
                <input type="text" onChange={this.onNameChange} required></input>
              </Col>
              <Col sm={12} md={4}>
                <label htmlFor="Email">Email*</label>
                <input type="text" onChange={this.onEmailChange} required></input>
                <div id="emailValidation" className="validationBox">
                  {this.state.emailValidation}
                </div>
              </Col>
              <Col sm={12} md={4}>
                <label htmlFor="Phone">Phone Number</label>
                <input type="text" onChange={this.onPhoneChange}></input>
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="Address">Address</label>
                <input type="text" onChange={this.onAddressChange}></input>
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  type="checkbox"
                  onChange={this.onCheckboxSelect}
                  checked={this.state.contactChecked}
                ></input>
                <label htmlFor="ContactMe" className="contactMe">
                  Contact me now to book an appointment
                </label>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    opacity: this.state.emailBtnOpacity,
                    pointerEvents: this.state.emailBtnPointerEvents,
                  }}
                >
                  Email Me The Quote
                </button>
                <div className="submitThanks" style={{ opacity: this.state.thanksOpacity }}>
                  <strong>Thank you for your enquiry! Email is now on its way.</strong>
                  <br />
                  If you have requested an appointment, we will get back to you as soon as possible.
                </div>
              </Col>
            </Row>
          </form>
        </Container>

        <div
          className="infoBoxQuote"
          style={{
            top: this.state.infoTop + "px",
            left: this.state.infoLeft + "px",
            opacity: this.state.infoOpacity,
          }}
        >
          {this.state.info}
        </div>

        <ModalPopup
          onClick={this.handleModal}
          closeModal={this.closeModal}
          opacity={this.state.modalOpacity}
          pointerEvents={this.state.modalPointerEvents}
        />
      </div>
    );
  }
}

export default withRouter(QuotePage);
