import React, { Component } from 'react'
import {
    Link,
    withRouter
  } from 'react-router-dom'
import '../Blog.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Helmet from 'react-helmet'
import PageHeader from '../../../components/PageHeader'
import YouTube from 'react-youtube'
import CallToAction from '../../../components/CallToAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

class HotWaterExtractionOrSteamCleaningPage extends Component {

  async componentDidMount() {
    document.getElementById("blog").classList.add("activeMenu");
    window.scroll(0, 0);
  }

  async componentWillUnmount() {
    document.getElementById("blog").classList.remove("activeMenu")
  }

  render() {
    return (
      <div className="articlePageWrapper">

      <Helmet>
        <title>Carpet Cleaning: Hot Water Extraction OR Steam Cleaning</title>
        <meta name="description" content="If you are considering having your carpets cleaned the one question that will confuse you is the difference between steam cleaning and hot water extraction cleaning." />
      </Helmet>

      <PageHeader imageId="quoteHeaderImg" name="Blog"/>

      <Container>
        <Row>
          <Col>
            <h2 className="sectionHead">Carpet Cleaning: Hot Water Extraction OR Steam Cleaning</h2>
            <img className="articleHeroImg" src="/blog/steam-vs-hotwater.jpg" alt="The Monsters in My Carpet"/>
            <p className="article-big bold">If you are considering having your carpets cleaned the one question that will confuse you is the difference between steam cleaning and hot water extraction cleaning.</p>
            <p className="article">The terms have become so misused in the industry that no one really uses them to describe the actual specific process but both terms are usually used interchangeably to refer to deep carpet cleaning in general. In actual fact the two are completely different ways of cleaning carpet. Both processes utilise different temperatures of water. Don’t try to clean your carpet or try to treat a nasty stain, learn more about hot water extraction & steam cleaning.</p>
            <p className="article">For steam cleaning a carpet you would have to use actual steam rather than hot water. It sounds simple and it is. But it’s also a big difference. The water has to be boiled. Only then will it turn into gas. And everyone knows the temperature has to reach 100c for the water to boil.</p>
            <p className="article">Now lets look at hot water extraction. In contrast it only requires the water to be hot. It just needs to be hot enough so it cleans your textile carpet deeply and effectively. If you are still confused about how hot, think washing machine hot when you wash your clothes.</p>
            <p className="article">So that’s the fundamental difference between hot water extraction & steam cleaning: the temperature of the water turning it into hot gas.</p>
            
            <p className="article bold">Which process should you use?</p>
            <p className="article">Now that you know the difference you would want to know which process you should use. Well, most carpet manufacturers will state that hot water extraction is the preferred method. Natural fibres in carpets & upholstery should be cleaned with hot water extraction so there is no shrinkage. Same applies to stains. They should also be cleaned with hot water extraction. If you use steam to attempt to clean a stain, it may set the stain in place permanently. Steam cleaning can be used with synthetic carpet. It’s a matter of opinion but some people prefer steam cleaning for synthetic carpet as they believe it cleans them better.</p>
            <p className="article">If you are thinking of cleaning your carpets, beware of the DIY equipment available from equipment hire shops and DIY stores. A lack of experience & generic, inadequate carpet cleaners can leave detergent residue behind which in turn will ensure that your carpet will attract more dust quicker. As a result your carpet will get dirtier quicker after you have cleaned it. If the cleaning isn’t done properly it may also cause mould growth.</p>
            <p className="article">At property people, our professional cleaners will use correct levels of detergent and leave no detergent residue that would attract dirt and quickly make your carpet dirty again. Trust property people carpet cleaners if you or your family are concerned about exposure to dangerous chemical compounds left by DIY carpet cleaners. Our professional carpet cleaners will also make sure that your carpet is left as little moisture as possible so that it can dry quickly. Call us today to get a quote. You will be amazed with the results</p>
           
            <p className="article bold">Conclusion</p>
            <p className="article">So hot water extraction or steam cleaning: which is the right one for your home? The terms have become so misused that it’s hard to say. But at home with textile carpets, hot water extraction is essentially what you want.</p>
            <p className="article">Just remember that in the carpet industry, hot water extraction is commonly being referred to as “steam cleaning.” If someone tells you that they will “steam clean” your textile carpet, just ask them to specify whether they will be using hot water or actual steam. You want to make sure they don’t damage your carpet. But in all likelihood they are using hot water extraction but using the term steam cleaning as it sounds better.</p>
            <p className="article">We often use this video from the Richard & Judy show as a reference to back up our claims. If you take a few minutes to watch it you will see the horrific facts behind how dirty your carpet is at a microscopic level. Simply put, your carpet is probably 5 times dirtier than the street outside your front door. Scary!</p>

            <YouTube videoId="dTpjc-rXG0A" />

            <p className="article">If you’re in the South West London area, give Property People a call. Our professional carpet cleaners use state of art German machinery to get your carpet visually & hygienically clean & smelling spring fresh. They’ll leave absolutely no residue afterwards.</p>
            
          </Col>
        </Row>

        <CallToAction />

        <Link to="blog">
          <FontAwesomeIcon icon={faAngleLeft}/> Back to blog
        </Link>

      </Container>
      
      </div>
    )
  }
}

export default withRouter (HotWaterExtractionOrSteamCleaningPage)