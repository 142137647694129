import React, { Component } from 'react';
import './QuoteSwitch.css'
import {
    Link,
    withRouter
  } from 'react-router-dom'

class QuoteSwitch extends Component {
	render() {
		return (
			<div className="quoteTabWrap">
				
					<Link 
						to="quote" 
						className="quoteTab"
						style={window.location.pathname === "/quote" ? {background:"var(--primary-blue)", color:"white"} : {background:"var(--light-blue)"}}
						>
							Residential Carpet Clean 
					</Link>

					<Link 
					to="quote-end-of-tenancy" 
					className="quoteTab"
					style={window.location.pathname === "/quote-end-of-tenancy" ? {background:"var(--primary-blue)", color:"white"} : {background:"var(--light-blue)"}}
					>
							End of Tenancy
					</Link>

					<Link 
					to="quote-commercial" 
					className="quoteTab"
					style={window.location.pathname === "/quote-commercial" ? {background:"var(--primary-blue)", color:"white"} : {background:"var(--light-blue)"}}
					>
							Commercial Carpet Clean
					</Link>

			</div>
		);
	}
}

export default withRouter(QuoteSwitch)