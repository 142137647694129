import React, { useState, useEffect } from "react";
import { deleteSession } from "../../utils";
import { useHistory } from "react-router-dom";
import { publicRequest } from "../../requestMethods";
import styled from "styled-components";
import JobAdAdminList from "../../fragments/JobAdAdminList";
import AddJobAdForm from "../../components/AddJobAdForm";
import Modal from "react-modal";
import { HighlightOffRounded } from "@material-ui/icons";

const PageTitle = styled.h1`
    color: var(--primary-blue);
`;

const CloseBtn = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
    cursor: pointer;
    & svg {
        fill: ${(props) => props.theme.lightblue};
    }
`;

// Modal
const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        zIndex: "10",
        border: "2px solid #a2def9",
        padding: "2rem",
    },
};

export default function Dashboard() {
    // Form input states
    const [jobAds, setJobAds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);

    // Modal states
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalError, setModalError] = useState(false);

    // Receive id from the list to be edited
    const [jobAdToEdit, setJobAdToEdit] = useState("");

    const history = useHistory();

    useEffect(() => {
        const getJobAds = async () => {
            try {
                const res = await publicRequest.get("/jobads");
                console.log(res.data);
                setJobAds(res.data);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        getJobAds();
    }, []);

    const handleLogout = () => {
        deleteSession();
        history.push(`/`);
    };

    // Modal handling
    const handleAddNewJob = () => {
        setModalIsOpen(true);
        setShowForm(true);
        setJobAdToEdit("");
    };

    const handleEditJob = () => {
        setModalIsOpen(true);
        setShowForm(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const closeModalAndReload = async () => {
        setIsLoading(true);
        try {
            const res = await publicRequest.get("/jobads");
            setJobAds(res.data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
        setModalIsOpen(false);
    };

    // const handleModalButton = () => {
    //     modalError ? closeModal() : history.push("/dashboard");
    // };

    Modal.setAppElement("#root"); // Need to set this for screen readers to avoid errors in development

    return (
        <div className="container" style={{ minHeight: "70vh" }}>
            <div className="row mt-5 mb-4">
                <div className="col-6">
                    <PageTitle>Job Advert Admin</PageTitle>
                </div>
                <div className="col-6 text-right">
                    <button onClick={handleAddNewJob} className="btn btn-primary mr-3">
                        Add New Job
                    </button>
                    <button onClick={handleLogout} className="btn btn-secondary">
                        Logout
                    </button>
                </div>
            </div>

            {/* Add New Job Modal */}
            <Modal isOpen={modalIsOpen} style={modalStyles} closeTimeoutMS={300}>
                <CloseBtn onClick={closeModal}>
                    <HighlightOffRounded />
                </CloseBtn>

                {showForm && (
                    <AddJobAdForm
                        closeModalAndReload={closeModalAndReload}
                        closeModal={closeModal}
                        jobAdToEdit={jobAdToEdit}
                    />
                )}
            </Modal>

            {/* List of Job Adverts */}
            {!isLoading &&
                jobAds.map((jobAd) => (
                    <JobAdAdminList
                        key={jobAd._id}
                        jobAd={jobAd}
                        setJobAdToEdit={setJobAdToEdit}
                        handleModalOpen={handleEditJob}
                    />
                ))}
        </div>
    );
}
